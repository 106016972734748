


































































































































































































import { Component, Prop} from 'vue-property-decorator';

import InputSearch from '@/views/label/companies/components/InputSearch.vue';
import {IFindOption, saveDownloadedBlob} from "@/repositories/repository-base";

import ListPageBase from "@/views/label/companies/ListPageBase";

import {
  StatusTotalCountForDisplay
} from '@/repositories/spec/company/spec-share-repository';
import {
  convertFromSpecShareForDisplay,
  SpecShareStatus,
  SpecShareStatusForDisplay,
  STATUS_DICT_FOR_RECEIVER
} from "@/entities/specs/spec-share-entity";
import LoadingHandler from "@/utils/loading-handler";
import SpecAcceptanceRepository from "@/repositories/spec/company/spec-acceptance-repository";
import {
  ImportDataType,
  ImportResponse,
} from "@/repositories/spec/company/spec-repository";
import {IFileUpload} from "@/contracts/i-file";
import SpecSelfSubmissionRepository, {ISpecSelfSubmissionCreateRequest} from "@/repositories/spec/company/spec-self-submission-repository";

import SpecImportMenu from "@/views/spec/companies/components/SpecImportMenu.vue";
import SelfSubmissionModal from '@/views/spec/companies/self-submissions/create/components/SelfSubmissionModal.vue';
import SpecShareForReceiverRepository, {
  ReceiveListItem
} from "@/repositories/spec/company/spec-share-for-receiver-repository";
import * as FileSaver from "file-saver";
import CompanyEntity from "@/entities/company-entity";
import {CompanyRepository} from "@/repositories/company-repository";
import ReconfirmNotification from "@/views/spec/companies/receives/components/ReconfirmNotification.vue";
import DepartmentAnnotationOnIndex from "@/views/components/Department/DepartmentAnnotationOnIndex.vue";

@Component({
  components: {
    DepartmentAnnotationOnIndex,
    ReconfirmNotification,
    InputSearch,
    SpecImportMenu,
    SelfSubmissionModal
  }
})
export default class extends ListPageBase {
  @Prop({required: false, default: 'all'}) private status!:SpecShareStatusForDisplay;

  private readonly Status = SpecShareStatusForDisplay;
  private readonly STATUS_DICT_FOR_RECEIVER = STATUS_DICT_FOR_RECEIVER;

  private readonly statusTagClassDict = {
    [SpecShareStatus.REQUEST_CANCELED]: 'info',
    [SpecShareStatus.REQUEST_DECLINED]: 'info',
    [SpecShareStatus.REQUESTED]: 'success',
    [SpecShareStatus.ACCEPTED]: 'info',
    [SpecShareStatus.RFC]: 'alert',
    [SpecShareStatus.SUBMITTED]: 'danger',
  }

  private countsByStatus:StatusTotalCountForDisplay[]|null = null;

  private beforeCreate() {
    this.routeName = 'receive';
  }

  protected async find(searchText, opt:IFindOption) {
    const statuses = convertFromSpecShareForDisplay(this.status);
    const query: { visibility?: 'visible' | 'hidden' } = this.getCurrentGETParameters();
    const res = await new SpecShareForReceiverRepository(this.companyId).list(searchText, statuses, opt, query);
    this.countsByStatus = res.counts;
    return res.data;
  }

  private getStatusCount(status: SpecShareStatusForDisplay | 'notification') {
    return this.countsByStatus!.find(s => s.status === status)!.total;
  }

  private get isAnyRowSelected(): boolean {
    return this.selectedRows.length > 0;
  }
  private get cancelEnabled(): boolean {
    return this.selectedRows.length === 1 && this.selectedRows[0].canAbort;
  }
  private get cloneEnabled(): boolean {
    return this.selectedRows.length === 1 && this.selectedRows[0].isSelfSubmission;
  }

  private onUpdateNotificationConfirmed(row: ReceiveListItem) {
    LoadingHandler(() => {
      return (new SpecAcceptanceRepository(this.companyId)).clearUpdateNotification(row.specAcceptanceId!).then(() => {
        row.receiveSpecUpdateNotificationCreatedAt = null;
      });
    });
  }

  private onReconfirmNotificationConfirmed(row: ReceiveListItem) {
    LoadingHandler(() => {
      return (new SpecAcceptanceRepository(this.companyId)).clearReconfirmNotification(row.specAcceptanceId!).then(() => {
        row.hasReconfirmNotification = false;
      });
    });
  }

  public cancelRequest() {
    const msg = 'この回収依頼を中断しますか？相手方にも、提出の必要性がないことを明示します。<br><br>同じ内容で回収したい場合には、再度回収依頼を出してください。';
    this.$confirm(this.$t(msg), this.$t('この回収依頼を中断する'), {
      dangerouslyUseHTMLString: true,
      confirmButtonText: this.$t('はい') ,
      confirmButtonClass: 'c-button primary-inverse',
      cancelButtonText: this.$t('いいえ') ,
      cancelButtonClass: 'c-button primary',
    })
      .then(async () => {
        const row: ReceiveListItem = this.selectedRows[0];
        await LoadingHandler(async () => {
          await new SpecShareForReceiverRepository(this.companyId).setStatusAsRequestCanceled(row.specShareId);
          this.$message({type: 'success', message: this.$t(`回収依頼を中断しました`) });
          return this.load();
        });
      })
      .catch(err => { if (err !== 'cancel') throw err; });
  }

  private p_visibility = (this.$route.query.visibility || 'visible') as 'visible' | 'hidden';
  private get visibility() { return this.p_visibility; }
  private set visibility(val) {
    this.p_visibility = val;
    this.$router.push({ query: { visibility: val } });
  }
  private get visibilityLabel() {
    return this.$t(this.visibility === 'visible' ? '非表示リスト' : '表示リスト');
  }
  private switchVisibility() {
    this.visibility = this.visibility === 'visible' ? 'hidden' : 'visible';
    this.$nextTick(() => {
      LoadingHandler(() => this.load());
    });
  }
  private async postToggleVisibility() {
    await LoadingHandler(async () => {
      const data = {
        specShareIdList: this.selectedRows.filter((r: ReceiveListItem) => !r.isSelfSubmission).map((r: ReceiveListItem) => r.specShareId),
        specSelfSubmissionMetaIdList: this.selectedRows.filter((r: ReceiveListItem) => r.isSelfSubmission).map((r: ReceiveListItem) => r.selfSubmissionMetaId!),
      };
      await new SpecShareForReceiverRepository(this.companyId).toggleVisibility(data);
      return this.load();
    });
  }
  private async cloneSelfSubmission() {
    if (!this.cloneEnabled) return;
    const row = this.selectedRows[0] as ReceiveListItem;
    this.$router.push({ name: 'self-submission.clone', params: { selfSubmissionId: row.selfSubmissionId as any} });
  }

  private get canEdit() {
    return this.$auth.user.isCompanyEditable;
  }
  private get visibleDepartment(): boolean {
    return this.company!.setting.isDepartmentEnabled;
  }

  private submissionModalVisible = false;
  private selectedImportFile:any = { type: null, file: null, params: {} };

  private onImportFileSelected(type:ImportDataType, file:IFileUpload|IFileUpload[], params?:object) {
    this.selectedImportFile = { type: type, file: file, params: params };
    this.submissionModalVisible = true;
  }
  private async onSubmitImportFile(submissionRequest:ISpecSelfSubmissionCreateRequest) {
    LoadingHandler(() => {
      return (new SpecSelfSubmissionRepository(this.companyId))
        .createByImporting(this.selectedImportFile.type, this.selectedImportFile.file, submissionRequest, this.selectedImportFile.params);
    }, 180000, {text: this.$t('この処理は数十秒かかる場合があります')}).then((res:ImportResponse) => {
      this.submissionModalVisible = false;

      if (res.success) {
        this.$message({type: 'success', message: this.$t('のデータ取り込みが完了しました', [res.data.name])});
        LoadingHandler(() => { return this.load();} );
      } else {
        this.$message({type: 'warning', message: this.$t('確認が必要な項目があります') });
        this.$router.push({name: 'self-submission.edit.import', params: {importingSpec: res.data as any, convertingError: res.error as any}, query: {'view': 'ingredient'}});
      }

    });
  }

  private exportAllCsv() {
    return LoadingHandler(async () => {
      const statuses = convertFromSpecShareForDisplay(this.status);
      const res = await (new SpecShareForReceiverRepository(this.companyId)).downloadAllQueriedCsv(this.searchText, statuses);
      FileSaver.saveAs(new Blob([res.data], {type: res.data.type } ), this.$t("回収規格書一覧.csv"));
    });
  }

  public row(scope: { row: ReceiveListItem}): ReceiveListItem {
    return scope.row;
  }
}
