









import { Component, Prop, Vue} from 'vue-property-decorator';

import {ValidateWithScroll} from "@/utils/validation-handler";

import {
  CreateShareRequestRequest
} from "@/repositories/spec/company/spec-share-for-receiver-repository";
import BasicSection from "@/views/spec/companies/receives/create-request/components/BasicSection.vue";
import RequiredFieldsSection
  from "@/views/spec/companies/receives/create-request/components/RequiredFieldsSection.vue";
import {notEmptyArrayRule, requiredRule} from "@/utils/validation-rules";

@Component({
  components: {
    RequiredFieldsSection,
    BasicSection,
  }
})
export default class CreateRequestBase extends Vue {
  @Prop({ required: true } ) private companyId!:number;
  @Prop({ required: true } ) private value!:CreateShareRequestRequest;

  private get rules(){
    return {
      targetType: [requiredRule],
      targetValue: [requiredRule],
      dueDate: [requiredRule],
      assigneeIds: [ notEmptyArrayRule ]
    };
  }
  private get request() { return this.value; }

  private requestingToDisplay = { error: '', name: '', loading: false };

  public async validate(): Promise<boolean> {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return false;

    if (this.request.targetType === 'uid' && !this.requestingToDisplay.name) {
      this.requestingToDisplay.error = this.$t('有効な事業者が選択されていません');
      return false;
    }

    try {
      await this.$confirm(this.$t(`規格書の提出依頼を送信します。よろしいですか？（すぐに事業者に送信されます）`), this.$t('規格書の提出依頼'), {
        confirmButtonText: this.$t('送信'),
        confirmButtonClass: 'c-button primary no-focus',
        cancelButtonText: this.$t('キャンセル'),
        cancelButtonClass: 'c-button primary-inverse no-focus',
      })
      return true;
    } catch(err) {
      if (err !== 'cancel') throw err;
      return false;
    }
  }

}
