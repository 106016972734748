





















































import { Component, Prop, Vue} from 'vue-property-decorator';

import StickyHeader from '@/components/StickyHeader.vue';

import { help as PopoverText } from '@/lang/help/spec-create';
import AuthViewBase from "@/views/AuthViewBase";
import AddDeleteTable from "@/components/Table/AddDeleteTable.vue";
import {
  requiredOnChangeRule,
} from "@/utils/validation-rules";
import LoadingHandler from "@/utils/loading-handler";
import isAfter from 'date-fns/isAfter';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import SpecShareForReceiverRepository, {CreateShareRequestRequest} from "@/repositories/spec/company/spec-share-for-receiver-repository";
import CreateRequestBase from "@/views/spec/companies/receives/create-request/CreateRequestBase.vue";
import RequiredFieldsSection
  from "@/views/spec/companies/receives/create-request/components/RequiredFieldsSection.vue";
import BasicSection from "@/views/spec/companies/receives/create-request/components/BasicSection.vue";

@Component({
  components: {
    BasicSection,
    RequiredFieldsSection,
    CreateRequestBase,
    StickyHeader,
    AddDeleteTable,
  }
})
export default class extends AuthViewBase {
  private readonly PopoverText = PopoverText;

  protected request: CreateShareRequestRequest = {
    targetType: null,
    targetValue: null,
    dueDate: null as any,
    assigneeIds: [ this.$auth.user.id ],
    comment: null,
    products: [],
    requiredFields: []
  };

  private requesterName = '';

  private get rules(){
    return {
      products: {
        name: [requiredOnChangeRule],
      }
    };
  }

  private async created() {
    const requestIdQuery = this.$route.query.requests as string;
    if (!requestIdQuery) {
      this.$message({ type: "error", message: "requestIdがありません" });
      this.$router.push({name: 'submission.index'});
      return;
    }

    const requestIds = requestIdQuery.split(",");

    Promise.all([
      (new SpecShareForReceiverRepository(this.companyId)).getRequests(requestIds).then(response => {
        this.requesterName = response.requesterName;
        this.request.requiredFields = response.requiredFields;
        this.request.products = response.requests.map(r => ({name: r.name, originalName: r.name, transferredRequestId: r.id }));
      }),
    ]).then(() => {
    });
  }

  private async submit() {
    if (!(await (this.$refs.base as CreateRequestBase).validate())) return;

    return LoadingHandler(async () => {
      return (new SpecShareForReceiverRepository(this.companyId)).createRequest(this.request).then(() => {
        this.$router.push({name: 'receive.index'}, () => {
          this.$message({type: 'success', message: this.$t(`規格書の提出依頼を転送しました`)});
        });
      });
    })
  }

}
